import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useAuthToken = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("user");

    if (!token) {
      navigate("/login");
    }

    const handleStorageChange = () => {
      const token = localStorage.getItem("user");
      if (!token) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  return !!localStorage.getItem("user");
};

export default useAuthToken;
