import { FaHome } from "react-icons/fa";
// import { TbCategoryPlus } from "react-icons/tb";
// import { BiSolidCategory } from "react-icons/bi";
import { MdProductionQuantityLimits } from "react-icons/md";
import { FaWpforms } from "react-icons/fa6";

export const menuItems = [
  {
    path: "/",
    icon: <FaHome className="text-xl" />,
    label: "DASHBOARD",
    roles: ["admin", "subadmin"],
  },
  {
    icon: <MdProductionQuantityLimits className="text-xl" />,
    label: "PRODUCTS",
    roles: ["admin", "subadmin"],
    subMenu: [
      {
        path: "/product-add",
        icon: <FaWpforms />,
        label: "Product Add",
      },
      {
        path: "/products",
        icon: <MdProductionQuantityLimits />,
        label: "Products",
      },
    ],
  },
];
